<template>
    <v-row class="pa-0 ma-0">
        <v-col :cols="model.paymentMethod === 'CQ' ? '3' : '12'">
            <vc-date-picker v-model="model.start_date" mode="date" :masks="{ L: 'YYYY-MM-DD' }" is-required
              :model-config="{type: 'string', mask: 'YYYY-MM-DD' }">
                <template v-slot="{ inputValue, togglePopover }">
                    <v-text-field @click="togglePopover" :readonly="true" placeholder="Payment Date" hide-details="auto" :value="inputValue" required
                    :error-messages="$helpers.errorMsg('name', v.model.start_date, 'Payment Date')"></v-text-field>
                </template>
            </vc-date-picker>
        </v-col>
       <template v-if="model.paymentMethod === 'CQ'">
            <v-col cols="4">
                <v-text-field placeholder="Drawer" hide-details="auto" v-model="model.drawer" required
                    :error-messages="$helpers.errorMsg('name', v.model.drawer, 'Drawer')"></v-text-field>
            </v-col>
            <v-col cols="5">
                <v-text-field type="number" placeholder="Cheque No" hide-details="auto" v-model.number="model.cheque_no" required
                    :error-messages="$helpers.errorMsg('name', v.model.cheque_no, 'Cheque No')"></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field hide-details="auto" dense type="number" v-model.number="model.bsb" placeholder="BSB" required
                    :error-messages="$helpers.errorMsg('name', v.model.bsb, 'BSB')"></v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field hide-details="auto" dense type="number" v-model.number="model.account_no" placeholder="Account No" required
                    :error-messages="$helpers.errorMsg('name', v.model.account_no, 'Account No')"></v-text-field>
            </v-col>
        </template>
    </v-row>
</template>

<script>
export default {
    props: ["v"],
    computed: {
        model: {
            get() {
                return this.$store.getters["model"];
            },
            set(newValue) {
                return this.$store.commit("setModel", newValue);
            },
        }
    },
    created () {
        if(this.model.start_date) this.model.start_date = this.$helpers.getMomentDatas('YYYY-MM-DD', this.model.start_date);
        else this.model.start_date = this.$helpers.getMomentDatas('YYYY-MM-DD');
    }
}
</script>